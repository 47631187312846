<template>
  <div>
    <div class="group pb-3">
      <span class="group-title d-block mb-20"
        ><i class="lnr lnr-user mr-8"></i>{{ $t("my_information") }}</span
      >
      <b-form-group
        :label="$t('form_email') + '*'"
        label-for="email"
        description="De bevestiging wordt naar dit e-mailadres verzonden."
      >
        <b-form-input
          id="email"
          v-model="form.email"
          type="text"
          :state="form.emailState"
          aria-describedby="email-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="email-feedback">
          {{ form.emailError }}
        </b-form-invalid-feedback>
      </b-form-group>
      <div v-if="emailExists">
        <b-form-group
          class="account-inputs"
          id="password-group"
          :label="$t('form_password') + '*'"
          label-for="password"
        >
          <b-form-input
            id="password"
            v-model="form.password"
            type="password"
            aria-describedby="password-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="password-feedback">
            {{ form.passwordError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="d-flex justify-content-between align-items-center">
          <b-link
            to="/forgot-password"
            class="font-weight-normal link-underline"
            >{{ $t("password_forgot") }}</b-link
          >
          <b-button
            type="button"
            variant="info"
            @click="doLogin"
            class="text-white ml-20 form-submit-btn"
            >{{ $t("login") }}</b-button
          >
        </div>
      </div>
    </div>
    <div class="group">
      <span class="group-title d-block mb-25"
        ><i class="lnr lnr-home mr-10"></i>{{ $t("shipping_address") }}</span
      >
      <b-form-checkbox id="checkbox-1" v-model="isCompany" name="checkbox-1">{{
        $t("form_are_you_a_company")
      }}</b-form-checkbox>
      <div v-if="isCompany" class="short-form">
        <b-form-group
          class="account-inputs"
          id="company-group"
          :label="$t('form_company_name') + '*'"
          label-for="company"
        >
          <b-form-input
            id="company"
            v-model="form.company"
            type="text"
            v-on:blur="validateCompany()"
            :state="form.companyState"
            aria-describedby="company-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="company-feedback">
            {{ form.companyError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <!--         <b-form-group
          class="account-inputs"
          id="coc_number-group"
          :label="$t('form_coc_number') + '*'"
          label-for="coc_number"
        >
          <b-form-input
            id="coc_number"
            v-model="form.coc_number"
            type="text"
            v-on:blur="validateCOCNumber()"
            :state="form.coc_numberState"
            aria-describedby="coc_number-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="coc_number-feedback">
            {{ form.coc_numberError }}
          </b-form-invalid-feedback>
        </b-form-group> -->
        <b-form-group
          class="account-inputs"
          id="taxvat-group"
          :label="$t('form_vat_number')"
          label-for="taxvat"
        >
          <b-form-input
            id="taxvat"
            v-model="form.taxvat"
            type="text"
            :state="form.taxvatState"
            aria-describedby="taxvat-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="taxvat-feedback">
            {{ form.taxvatError }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>

      <b-form-group
        class="account-inputs"
        id="first_name-group"
        :label="$t('form_first_name') + '*'"
        label-for="first_name"
      >
        <b-form-input
          id="first_name"
          v-model="form.firstname"
          type="text"
          v-on:blur="validateFirstname()"
          :state="form.firstnameState"
          aria-describedby="firstname-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="firstname-feedback">
          {{ form.firstnameError }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        class="account-inputs"
        id="last_name-group"
        :label="$t('form_last_name') + '*'"
        label-for="last_name"
      >
        <b-form-input
          id="last_name"
          v-model="form.lastname"
          type="text"
          v-on:blur="validateLastname()"
          :state="form.lastnameState"
          aria-describedby="lastname-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="lastname-feedback">
          {{ form.lastnameError }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        class="account-inputs"
        id="country-group"
        :label="$t('form_country') + '*'"
        label-for="country"
      >
        <b-form-select
          id="country"
          v-model="form.address.country_code"
          :options="countries"
          class="select"
        ></b-form-select>
      </b-form-group>
      <b-form-group
        v-if="!showFullAddress"
        class="account-inputs"
        id="postcode-group"
        :label="$t('form_postal_code') + '*'"
        label-for="postcode"
      >
        <b-form-input
          id="postcode"
          v-model="form.address.postcode"
          type="text"
          :state="form.address.postcodeState"
          aria-describedby="address.postcode-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="address.postcode-feedback">
          {{ form.address.postcodeError }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        v-if="showFullAddress"
        class="account-inputs"
        id="street-group"
        :label="$t('form_street') + '*'"
        label-for="street"
      >
        <b-form-input
          id="street"
          v-model="form.address.streetDisplay"
          type="text"
          v-on:blur="validateStreet()"
          :state="form.address.streetDisplayState"
          aria-describedby="address.streetDisplay-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="address.streetDisplay-feedback">
          {{ form.address.streetDisplayError }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-row>
        <b-col sm="6" class="first">
          <b-form-group
            class="account-inputs"
            id="house_number-group"
            :label="$t('form_house_number') + '*'"
            label-for="house_number"
          >
            <b-form-input
              id="house_number"
              v-model="form.address.house_number"
              type="text"
              v-on:blur="validateHouseNumber()"
              :state="form.address.house_numberState"
              aria-describedby="address.house_number-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="address.house_number-feedback">
              {{ form.address.house_numberError }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col sm="6" class="third">
          <b-form-group
            class="account-inputs"
            id="addition-group"
            :label="$t('form_addition')"
            label-for="addition"
          >
            <b-form-input
              id="addition"
              v-model="form.address.addition"
              type="text"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group
        v-if="showFullAddress"
        class="account-inputs"
        id="postcode-group1"
        :label="$t('form_postal_code') + '*'"
        label-for="postcode1"
      >
        <b-form-input
          id="postcode1"
          v-model="form.address.postcode"
          type="text"
          v-on:blur="validatePostcode()"
          :state="form.address.postcodeState"
          aria-describedby="address.postcode1-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="address.postcode1-feedback">
          {{ form.address.postcodeError }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        v-if="showFullAddress"
        class="account-inputs"
        id="city-group"
        :label="$t('form_city') + '*'"
        label-for="city"
      >
        <b-form-input
          id="city"
          v-model="form.address.city"
          type="text"
          v-on:blur="validateCity()"
          :state="form.address.cityState"
          aria-describedby="address.city-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="address.city-feedback">
          {{ form.address.cityError }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        v-if="showShippingRegion"
        class="account-inputs"
        id="region-group"
        :label="$t('region') + '*'"
        label-for="region_id"
      >
        <b-form-select
          id="region_id"
          v-model="form.address.region_id"
          :options="getShippingRegion"
          class="select"
        ></b-form-select>
        <b-form-invalid-feedback id="address.region-feedback">
          {{ form.address.regionError }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        v-if="foundAddress != '' && showFullAddress == false"
        class="account-inputs"
        id="add-group"
        label=" "
        label-for="add"
      >
        <div id="add">
          {{ foundAddress }}
        </div>
      </b-form-group>
      <b-form-group
        v-if="foundAddresses.length > 0 && showFullAddress == false"
        class="account-inputs"
        id="addm-group"
        label=" "
        label-for="addm"
      >
        <div id="addm">
          <b-form-radio
            style="width: 300px"
            class="account-radios"
            v-model="selectedAddress"
            v-for="(item, index) in foundAddresses"
            :key="`nl-${index}`"
            name="some-radios"
            :value="index"
            >{{ item.text }}</b-form-radio
          >
        </div>
      </b-form-group>
      <b-form-checkbox
        v-if="showManual"
        id="manual"
        v-model="showFullAddress"
        name="manual"
        >{{ $t("form_fillout_address_manual") }}</b-form-checkbox
      >
      <b-form-group
        class="account-inputs"
        id="telephone-group"
        :label="$t('form_phone') + '*'"
        label-for="telephone"
      >
        <b-form-input
          id="telephone"
          v-model="form.address.telephone"
          type="text"
          v-on:blur="validatePhone()"
          :state="form.address.telephoneState"
          aria-describedby="address.telephone-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="address.telephone-feedback">
          {{ form.address.telephoneError }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-checkbox
        id="checkbox-2"
        name="checkbox-2"
        label-for="checkbox2"
        v-model="form.address.default_billing"
        >{{ $t("form_address_also_invoice_address") }}</b-form-checkbox
      >
    </div>
    <div class="group" v-if="form.address.default_billing == false">
      <span class="group-title d-block mb-25"
        ><i class="lnr lnr-home mr-10"></i>{{ $t("invoice_address") }}</span
      >
      <b-form-checkbox
        id="checkbox-99"
        v-model="isInvoiceCompany"
        name="checkbox-99"
        >{{ $t("form_are_you_a_company") }}</b-form-checkbox
      >
      <div v-if="isInvoiceCompany" class="short-form">
        <b-form-group
          class="account-inputs"
          id="company-group"
          :label="$t('form_company_name') + '*'"
          label-for="company"
        >
          <b-form-input
            id="company"
            v-model="form.invoice.company"
            type="text"
            v-on:blur="validateInvoiceCompany()"
            :state="form.invoice.companyState"
            aria-describedby="company-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="company-feedback">
            {{ form.invoice.companyError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <!--         <b-form-group
          class="account-inputs"
          id="coc_number-group"
          :label="$t('form_coc_number') + '*'"
          label-for="coc_number"
        >
          <b-form-input
            id="coc_number"
            v-model="form.invoice.coc_number"
            type="text"
            v-on:blur="validateInvoiceCOCNumber()"
            :state="form.invoice.coc_numberState"
            aria-describedby="coc_number-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="coc_number-feedback">
            {{ form.invoice.coc_numberError }}
          </b-form-invalid-feedback>
        </b-form-group>
 -->
        <b-form-group
          class="account-inputs"
          id="taxvat-group"
          :label="$t('form_vat_number')"
          label-for="taxvat"
        >
          <b-form-input
            id="taxvat"
            v-model="form.invoice.taxvat"
            type="text"
            :state="form.invoice.taxvatState"
            aria-describedby="taxvat-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="taxvat-feedback">
            {{ form.invoice.taxvatError }}
          </b-form-invalid-feedback>
        </b-form-group>
      </div>

      <b-form-group
        class="account-inputs"
        id="first_name-group"
        :label="$t('form_first_name') + '*'"
        label-for="first_name"
      >
        <b-form-input
          id="first_name"
          v-model="form.invoice.firstname"
          type="text"
          v-on:blur="validateInvoiceFirstname()"
          :state="form.invoice.firstnameState"
          aria-describedby="firstname-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="firstname-feedback">
          {{ form.invoice.firstnameError }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        class="account-inputs"
        id="last_name-group"
        :label="$t('form_last_name') + '*'"
        label-for="last_name"
      >
        <b-form-input
          id="last_name"
          v-model="form.invoice.lastname"
          type="text"
          v-on:blur="validateInvoiceLastname()"
          :state="form.invoice.lastnameState"
          aria-describedby="lastname-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="lastname-feedback">
          {{ form.invoice.lastnameError }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        class="account-inputs"
        id="country-group"
        :label="$t('form_country') + '*'"
        label-for="country"
      >
        <b-form-select
          id="country"
          v-model="form.invoice.address.country_code"
          :options="countries"
          class="select"
        ></b-form-select>
      </b-form-group>
      <b-form-group
        v-if="!showFullInvoiceAddress"
        class="account-inputs"
        id="postcode-group"
        :label="$t('form_postal_code') + '*'"
        label-for="postcode"
      >
        <b-form-input
          id="postcode"
          v-model="form.invoice.address.postcode"
          type="text"
          :state="form.invoice.address.postcodeState"
          aria-describedby="address.postcode-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="address.postcode-feedback">
          {{ form.invoice.address.postcodeError }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        v-if="showFullInvoiceAddress"
        class="account-inputs"
        id="street-group"
        :label="$t('form_street') + '*'"
        label-for="street"
      >
        <b-form-input
          id="street"
          v-model="form.invoice.address.streetDisplay"
          type="text"
          v-on:blur="validateInvoiceStreet()"
          :state="form.invoice.address.streetDisplayState"
          aria-describedby="address.streetDisplay-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="address.streetDisplay-feedback">
          {{ form.invoice.address.streetDisplayError }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-row>
        <b-col sm="6" class="first">
          <b-form-group
            class="account-inputs"
            id="house_number-group"
            :label="$t('form_house_number') + '*'"
            label-for="house_number"
          >
            <b-form-input
              id="house_number"
              v-model="form.invoice.address.house_number"
              type="text"
              v-on:blur="validateInvoiceHouseNumber()"
              :state="form.invoice.address.house_numberState"
              aria-describedby="address.house_number-feedback"
            ></b-form-input>
            <b-form-invalid-feedback id="address.house_number-feedback">
              {{ form.invoice.address.house_numberError }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col sm="6" class="third">
          <b-form-group
            class="account-inputs"
            id="addition-group"
            :label="$t('form_addition')"
            label-for="addition"
          >
            <b-form-input
              id="addition"
              v-model="form.invoice.address.addition"
              type="text"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group
        v-if="showFullInvoiceAddress"
        class="account-inputs"
        id="postcode-group1"
        :label="$t('form_postal_code') + '*'"
        label-for="postcode1"
      >
        <b-form-input
          id="postcode1"
          v-model="form.invoice.address.postcode"
          type="text"
          v-on:blur="validateInvoicePostcode()"
          :state="form.invoice.address.postcodeState"
          aria-describedby="address.postcode1-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="address.postcode1-feedback">
          {{ form.invoice.address.postcodeError }}
        </b-form-invalid-feedback>
      </b-form-group>

      <b-form-group
        v-if="showFullInvoiceAddress"
        class="account-inputs"
        id="city-group"
        :label="$t('form_city') + '*'"
        label-for="city"
      >
        <b-form-input
          id="city"
          v-model="form.invoice.address.city"
          type="text"
          v-on:blur="validateInvoiceCity()"
          :state="form.invoice.address.cityState"
          aria-describedby="address.city-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="address.city-feedback">
          {{ form.invoice.address.cityError }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        v-if="showInvoiceRegion"
        class="account-inputs"
        id="region-group"
        :label="$t('region') + '*'"
        label-for="region_id"
      >
        <b-form-select
          id="region_id"
          v-model="form.invoice.address.region_id"
          :options="getInvoiceRegion"
          class="select"
        ></b-form-select>
        <b-form-invalid-feedback id="address.region-feedback">
          {{ form.address.regionError }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        v-if="foundInvoiceAddress != '' && showFullInvoiceAddress == false"
        class="account-inputs"
        id="add-group"
        label=" "
        label-for="add"
      >
        <div id="add">
          {{ foundInvoiceAddress }}
        </div>
      </b-form-group>
      <b-form-group
        v-if="
          foundInvoiceAddresses.length > 0 && showFullInvoiceAddress == false
        "
        class="account-inputs"
        id="addm-group"
        label=" "
        label-for="addm"
      >
        <div id="addm">
          <b-form-radio
            style="width: 300px"
            class="account-radios"
            v-model="selectedInvoiceAddress"
            v-for="(item, index) in foundInvoiceAddresses"
            :key="`add-${index}`"
            name="some-radios"
            :value="index"
            >{{ item.text }}</b-form-radio
          >
        </div>
      </b-form-group>
      <b-form-checkbox
        v-if="showManual"
        id="manual2"
        v-model="showFullInvoiceAddress"
        name="manual2"
        >{{ $t("form_fillout_address_manual") }}</b-form-checkbox
      >
      <b-form-group
        class="account-inputs"
        id="telephone-group"
        :label="$t('form_phone') + '*'"
        label-for="telephone"
      >
        <b-form-input
          id="telephone"
          v-model="form.invoice.address.telephone"
          type="text"
          v-on:blur="validateInvoicePhone()"
          :state="form.invoice.address.telephoneState"
          aria-describedby="address.telephone-feedback"
        ></b-form-input>
        <b-form-invalid-feedback id="address.telephone-feedback">
          {{ form.invoice.address.telephoneError }}
        </b-form-invalid-feedback>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import config from "@config";
import { Logger } from "@storefront/core/lib/logger";
import {
  validatePostcode,
  validateVatNumber,
  checkEmailExist,
} from "@storefront/core/data-resolver/user";
import Cart from "@storefront/core/modules/cart/mixins";

export default {
  name: "NotLoggedInCheckoutDetails",
  mixins: [Cart],
  mounted() {
    if (typeof config.customers.defaultCountry != "undefined") {
      this.form.address.country_code = config.customers.defaultCountry;
      this.form.invoice.address.country_code = config.customers.defaultCountry;
    }
    if (config.postcode_validation.enabled == false) {
      this.manual = true;
      this.showManual = false;
    }
    if (config.vat_validation.enabled == false) {
      this.showVatCheck = false;
    }
  },

  methods: {
    async validateEmail() {
      if (this.form.email.length == 0) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_required_field");
        return false;
      }
      if (!this.emailReg.test(this.form.email)) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_invalid_field");
        return false;
      }
      if (!(await checkEmailExist(this.form.email))) {
        this.emailExists = true;
        //this.form.emailError = this.$t("email_already_exists");
        //return false;
      } else {
        this.emailExists = false;
      }

      this.form.emailState = true;
      Logger.debug("calling cart/addCartEmail", "store", this.form.email)();
      this.$store.dispatch("cart/addCartEmail", {
        email: this.form.email,
      });
      return true;
    },
    async doLogin() {
      const loginOk = await this.$store.dispatch("user/login", {
        username: this.form.email,
        password: this.form.password,
      });

      if (loginOk == true) {
        const msg = {
          type: "success",
          title: this.$t("login_success"),
          text: this.$t("you_are_logged_in"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
        //this.$router.push({ name: "account" });
      }
    },
    async validateVAT() {
      if (this.isCompany == true) {
        if (
          this.form.taxvat.substring(0, 2).toUpperCase() ===
          this.form.address.country_code
        ) {
          const retval = await validateVatNumber(this.form.taxvat);
          Logger.debug("vatcheck", "validateVat", retval.valid)();
          if (retval.valid == "true") {
            this.isValidVatNumber = true;
            this.form.taxvatState = true;
          } else {
            this.isValidVatNumber = false;
            this.form.taxvatState = false;
            this.form.taxvatError = this.$t("taxvat_invalid");

            this.showVatMessage = this.$t("taxvat_invalid");
          }
        } else {
          this.isValidVatNumber = false;
          this.form.taxvatState = false;
          this.form.taxvatError = this.$t("taxvat_wrong_country");
        }
      } else {
        this.form.taxvatState = null;
      }
    },
    async validateAddress() {
      let isOk = true;
      if (this.isCompany == true) {
        if (this.form.company == "") {
          isOk = false;
        }
        /*
        if (this.form.taxvat == "") {
          isOk = false;
        }
        */
      }
      if (this.form.firstname == "") {
        isOk = false;
      }
      if (this.form.lastname == "") {
        isOk = false;
      }
      if (this.form.address.country_code == "") {
        isOk = false;
      }
      if (this.form.address.postcode == "") {
        isOk = false;
      }
      if (this.form.address.streetDisplay == "") {
        isOk = false;
      }
      if (this.form.address.house_number == "") {
        isOk = false;
      }
      if (this.form.address.city == "") {
        isOk = false;
      }
      if (this.form.address.telephone == "") {
        isOk = false;
      }
      if (
        this.isRegionRequired(this.form.address.country_code) &&
        this.form.address.region_id == ""
      ) {
        isOk = false;
      }

      if (isOk == true) {
        const address = {
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          postcode: this.form.address.postcode,
          city: this.form.address.city,
          telephone: this.form.address.telephone,
          street: `${this.form.address.streetDisplay} ${this.form.address.house_number}${this.form.address.addition}`,
          country_code: this.form.address.country_code,
          region_id: this.form.address.region_id,
        };
        if (this.isCompany == true) {
          address.company = this.form.company;
          if (this.form.taxvat != "") {
            address.vat_id = this.form.taxvat;
          }
        }
        this.$store.dispatch("cart/addShippingAddress", { address: address });
        if (this.form.address.default_billing == true) {
          this.$store.dispatch("cart/addBillingAddress", { address: address });
        }
      }
    },
    async validateInvoiceAddress() {
      let isOk = true;
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.company == "") {
          isOk = false;
        }
        /*
        if (this.form.invoice.taxvat == "") {
          isOk = false;
        }
        */
      }
      if (this.form.invoice.firstname == "") {
        isOk = false;
      }
      if (this.form.invoice.lastname == "") {
        isOk = false;
      }
      if (this.form.invoice.address.country_code == "") {
        isOk = false;
      }
      if (this.form.invoice.address.postcode == "") {
        isOk = false;
      }
      if (this.form.invoice.address.streetDisplay == "") {
        isOk = false;
      }
      if (this.form.invoice.address.house_number == "") {
        isOk = false;
      }
      if (this.form.invoice.address.city == "") {
        isOk = false;
      }
      if (this.form.invoice.address.telephone == "") {
        isOk = false;
      }
      if (
        this.isRegionRequired(this.form.invoice.address.country_code) &&
        this.form.invoice.address.region_id == ""
      ) {
        isOk = false;
      }
      if (isOk == true) {
        const address = {
          company: this.form.invoice.company,
          firstname: this.form.invoice.firstname,
          lastname: this.form.invoice.lastname,
          postcode: this.form.invoice.address.postcode,
          city: this.form.invoice.address.city,
          telephone: this.form.invoice.address.telephone,
          street: `${this.form.invoice.address.streetDisplay} ${this.form.invoice.address.house_number}${this.form.invoice.address.addition}`,
          country_code: this.form.invoice.address.country_code,
          region_id: this.form.invoice.address.region_id,
        };
        if (this.invoice.isCompany == true) {
          address.company = this.form.invoice.company;
          if (this.form.invoice.taxvat != "") {
            address.vat_id = this.form.invoice.taxvat;
          }
        }

        this.$store.dispatch("cart/addBillingAddress", { address: address });
      }
    },
    validateCompany() {
      if (this.isCompany == true) {
        if (this.form.company == "") {
          this.form.companyState = false;
          this.form.companyError = this.$t("company_name_required");
        } else {
          this.form.companyState = true;
        }
      } else {
        this.form.companyState = null;
      }
    },
    validateFirstname() {
      if (this.form.firstname == "") {
        this.form.firstnameState = false;
        this.form.firstnameError = this.$t("firstname_required");
      } else {
        this.form.firstnameState = true;
      }
    },
    validateLastname() {
      if (this.form.lastname == "") {
        this.form.lastnameState = false;
        this.form.lastnameError = this.$t("lastname_required");
      } else {
        this.form.lastnameState = true;
      }
    },
    validateCOCNumber() {
      if (this.isCompany == true) {
        if (this.form.coc_number == "") {
          this.form.coc_numberState = false;
          this.form.coc_numberError = this.$t("coc_number_required");
        } else {
          this.form.coc_numberState = true;
        }
      } else {
        this.form.coc_numberState = null;
      }
    },
    validateStreet() {
      if (this.form.address.streetDisplay == "") {
        this.form.address.streetDisplayState = false;
        this.form.address.streetDisplayError = this.$t("street_required");
      } else {
        this.form.address.streetDisplayState = true;
      }
    },
    validateHouseNumber() {
      if (this.form.address.house_number == "") {
        this.form.address.house_numberState = false;
        this.form.address.house_numberError = this.$t("house_number_required");
      } else {
        this.form.address.house_numberState = true;
      }
    },
    validateCity() {
      if (this.form.address.city == "") {
        this.form.address.cityState = false;
        this.form.address.cityError = this.$t("city_required");
      } else {
        this.form.address.cityState = true;
      }
    },
    validatePhone() {
      if (this.form.address.telephone == "") {
        this.form.address.telephoneState = false;
        this.form.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.address.telephoneState = true;
      }
    },
    async validatePostcodeHouseNumber() {
      if (this.showFullAddress == false) {
        const list = await validatePostcode(
          this.form.address.postcode,
          this.form.address.house_number
        );
        if (list.length == 1) {
          let address = list[0].street + " " + list[0].number;
          this.form.address.streetDisplay = list[0].street;
          if (list[0].letter != null) {
            this.form.address.addition = list[0].letter;
            address = address + list[0].letter;
          } else {
            this.form.addition = "";
          }

          address = address + ", " + list[0].postcode + " " + list[0].city;

          this.form.address.city = list[0].city;
          this.form.address.cityState == true;
          this.foundAddress = address;
          this.validatePostcode();
        } else if (list.length == 0) {
          this.foundAddress = this.$t("postcode_not_found");
          //Logger.debug("PostcodeValidation", "length 0", list.length)();
        } else {
          list.forEach(function (item) {
            let address = item.street + " " + item.number;
            if (item.letter != null) {
              address = address + item.letter;
            }

            address = address + ", " + item.postcode + " " + item.city;
            item.text = address;
            Logger.debug("multiple addresses", "item", item)();
            this.foundAddresses.push(item);
          }, this);
        }
      } else {
        if (this.form.address.postcode == "") {
          this.form.address.postcodeState = false;
          this.form.address.postcodeError = this.$t("postcode_required");
        } else {
          this.form.address.postcodeState = true;
        }
      }
    },
    validatePostcode() {
      if (this.form.address.postcode == "") {
        this.form.address.postcodeState = false;
        this.form.address.postcodeError = this.$t("postcode_required");
      } else {
        this.form.address.postcodeState = true;
      }
    },
    async validateInvoiceVAT() {
      if (this.isInvoiceCompany == true) {
        if (
          this.form.invoice.taxvat.substring(0, 2).toUpperCase() ===
          this.form.invoice.address.country_code
        ) {
          const retval = await validateVatNumber(this.form.invoice.taxvat);
          Logger.debug("vatcheck", "validateVat", retval.valid)();
          if (retval.valid == "true") {
            this.isValidInvoiceVatNumber = true;
            this.form.invoice.taxvatState = true;
          } else {
            this.isValidInvoiceVatNumber = false;
            this.form.invoice.taxvatState = false;
            this.form.invoice.taxvatError = this.$t("taxvat_invalid");
          }
        } else {
          this.isValidInvoiceVatNumber = false;
          this.form.invoice.taxvatState = false;
          this.form.invoice.taxvatError = this.$t("taxvat_wrong_country");
        }
      } else {
        this.form.invoice.taxvatState = null;
      }
    },
    validateInvoiceCompany() {
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.company == "") {
          this.form.invoice.companyState = false;
          this.form.invoice.companyError = this.$t("company_name_required");
        } else {
          this.form.invoice.companyState = true;
        }
      } else {
        this.form.invoice.companyState = null;
      }
    },
    validateInvoiceFirstname() {
      if (this.form.invoice.firstname == "") {
        this.form.invoice.firstnameState = false;
        this.form.invoice.firstnameError = this.$t("firstname_required");
      } else {
        this.form.invoice.firstnameState = true;
      }
    },
    validateInvoiceLastname() {
      if (this.form.invoice.lastname == "") {
        this.form.invoice.lastnameState = false;
        this.form.invoice.lastnameError = this.$t("lastname_required");
      } else {
        this.form.invoice.lastnameState = true;
      }
    },
    validateInvoiceCOCNumber() {
      if (this.isInvoiceCompany == true) {
        if (this.form.invoice.coc_number == "") {
          this.form.invoice.coc_numberState = false;
          this.form.invoice.coc_numberError = this.$t("coc_number_required");
        } else {
          this.form.invoice.coc_numberState = true;
        }
      } else {
        this.form.invoice.coc_numberState = null;
      }
    },
    validateInvoiceStreet() {
      if (this.form.invoice.address.streetDisplay == "") {
        this.form.invoice.address.streetDisplayState = false;
        this.form.invoice.address.streetDisplayError =
          this.$t("street_required");
      } else {
        this.form.invoice.address.streetDisplayState = true;
      }
    },
    validateInvoiceHouseNumber() {
      if (this.form.invoice.address.house_number == "") {
        this.form.invoice.address.house_numberState = false;
        this.form.invoice.address.house_numberError = this.$t(
          "house_number_required"
        );
      } else {
        this.form.invoice.address.house_numberState = true;
      }
    },
    validateInvoiceCity() {
      if (this.form.invoice.address.city == "") {
        this.form.invoice.address.cityState = false;
        this.form.invoice.address.cityError = this.$t("city_required");
      } else {
        this.form.invoice.address.cityState = true;
      }
    },
    validateInvoicePhone() {
      if (this.form.invoice.address.telephone == "") {
        this.form.invoice.address.telephoneState = false;
        this.form.invoice.address.telephoneError = this.$t("phone_required");
      } else {
        this.form.invoice.address.telephoneState = true;
      }
    },
    async validateInvoicePostcodeHouseNumber() {
      if (this.showFullAddress == false) {
        const list = await validatePostcode(
          this.form.invoice.address.postcode,
          this.form.invoice.address.house_number
        );
        if (list.length == 1) {
          let address = list[0].street + " " + list[0].number;
          this.form.invoice.address.streetDisplay = list[0].street;
          if (list[0].letter != null) {
            this.form.invoice.address.addition = list[0].letter;
            address = address + list[0].letter;
          } else {
            this.form.invoice.addition = "";
          }

          address = address + ", " + list[0].postcode + " " + list[0].city;

          this.form.invoice.address.city = list[0].city;
          this.form.invoice.address.cityState == true;
          this.foundInvoiceAddress = address;
          this.validatePostcode();
        } else if (list.length == 0) {
          this.foundInvoiceAddress = this.$t("postcode_not_found");
          //Logger.debug("PostcodeValidation", "length 0", list.length)();
        } else {
          list.forEach(function (item) {
            let address = item.street + " " + item.number;
            if (item.letter != null) {
              address = address + item.letter;
            }

            address = address + ", " + item.postcode + " " + item.city;
            item.text = address;
            Logger.debug("multiple addresses", "item", item)();
            this.foundInvoiceAddresses.push(item);
          }, this);
        }
      } else {
        if (this.form.invoice.address.postcode == "") {
          this.form.invoice.address.postcodeState = false;
          this.form.invoice.address.postcodeError =
            this.$t("postcode_required");
        } else {
          this.form.invoice.address.postcodeState = true;
        }
      }
    },
    validateInvoicePostcode() {
      if (this.form.invoice.address.postcode == "") {
        this.form.invoice.address.postcodeState = false;
        this.form.invoice.address.postcodeError = this.$t("postcode_required");
      } else {
        this.form.invoice.address.postcodeState = true;
      }
    },
    isRegionRequired(country_code) {
      return config.region_required.countries.includes(country_code);
    },
  },
  computed: {
    countries() {
      return this.$store.getters["user/getCountries"];
    },
    country() {
      return this.form.address.country_code;
    },
    regions() {
      var region = {};
      config.region_required.countries.forEach((cntry) => {
        region[cntry] = this.$store.getters["user/getRegions"](cntry);
      });
      return region;
    },
    getShippingRegion() {
      return this.regions[this.form.address.country_code];
    },
    getInvoiceRegion() {
      return this.regions[this.form.invoice.address.country_code];
    },
    address() {
      return `${this.form.company}|${this.form.taxvat}|${this.form.firstname}|${this.form.lastname}|${this.form.address.country_code}|${this.form.address.city}|${this.form.address.streetDisplay}|${this.form.address.house_number}|${this.form.address.addition}|${this.form.address.telephone}||${this.form.address.city}`;
    },
    invoiceAddress() {
      return `${this.form.invoice.company}|${this.form.invoice.taxvat}|${this.form.invoice.firstname}|${this.form.invoice.lastname}|${this.form.invoice.address.country_code}|${this.form.invoice.address.city}|${this.form.invoice.address.streetDisplay}|${this.form.invoice.address.house_number}|${this.form.invoice.address.addition}|${this.form.invoice.address.telephone}||${this.form.invoice.address.city}`;
    },
    invoiceCountry() {
      return this.form.invoice.address.country_code;
    },
    postcodeHouseNumber() {
      return `${this.form.address.postcode}|${this.form.address.house_number}`;
    },
    invoicePostcodeHouseNumber() {
      return `${this.form.invoice.address.postcode}|${this.form.invoice.address.house_number}`;
    },
    vatNumber() {
      return this.form.taxvat;
    },
    invoiceVatNumber() {
      return this.form.invoice.taxvat;
    },
    Email() {
      return this.form.email;
    },
    Password() {
      return this.form.password;
    },
    PasswordConfirm() {
      return this.form.password_confirm;
    },
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  watch: {
    country() {
      if (config.postcode_validation.enabled) {
        if (!config.postcode_validation.countries.includes(this.country)) {
          this.showFullAddress = true;
        } else {
          this.showFullAddress = false;
        }
      } else {
        this.showFullAddress = true;
      }
    },
    address() {
      if (this.form.addressTimer !== null) {
        clearTimeout(this.form.addressTimer);
      }
      this.form.addressTimer = setTimeout(
        function (scope) {
          scope.validateAddress();
        },
        1000,
        this
      );
    },
    invoiceAddress() {
      if (this.form.invoiceAddressTimer !== null) {
        clearTimeout(this.form.invoiceAddressTimer);
      }
      this.form.invoiceAddressTimer = setTimeout(
        function (scope) {
          scope.validateInvoiceAddress();
        },
        1000,
        this
      );
    },
    invoiceCountry() {
      if (config.postcode_validation.enabled) {
        if (
          !config.postcode_validation.countries.includes(this.invoiceCountry)
        ) {
          this.showFullInvoiceAddress = true;
        } else {
          this.showFullInvoiceAddress = false;
        }
      } else {
        this.showFullInvoiceAddress = true;
      }
    },
    vatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.taxvatTimer !== null) {
          clearTimeout(this.form.taxvatTimer);
        }
        this.form.taxvatTimer = setTimeout(
          function (scope) {
            scope.validateVAT();
          },
          1000,
          this
        );
      }
    },
    invoiceVatNumber() {
      if (config.vat_validation.enabled) {
        if (this.form.invoice.taxvatTimer !== null) {
          clearTimeout(this.form.invoice.taxvatTimer);
        }
        this.form.invoice.taxvatTimer = setTimeout(
          function (scope) {
            scope.validateInvoiceVAT();
          },
          1000,
          this
        );
      }
    },
    postcodeHouseNumber(newVal) {
      if (this.showFullAddress == false) {
        if (this.typeTimer !== null) {
          clearTimeout(this.typeTimer);
        }
        if (this.foundAddress != "") {
          this.foundAddress = "";
        }
        if (this.foundAddresses.length > 0) {
          this.foundAddresses = [];
        }
        //const [oldPostcode, oldHouseNumber] = oldVal.split("|");
        const [newPostcode, newHouseNumber] = newVal.split("|");
        if (newPostcode != "" && newHouseNumber != "") {
          this.typeTimer = setTimeout(
            function (scope) {
              scope.validatePostcodeHouseNumber();
            },
            2000,
            this
          );
        }
      }
    },
    invoicePostcodeHouseNumber(newVal) {
      if (this.showFullAddress == false) {
        if (this.typeTimer !== null) {
          clearTimeout(this.typeTimer);
        }
        if (this.foundInvoiceAddress != "") {
          this.foundInvoiceAddress = "";
        }
        if (this.foundInvoiceAddresses.length > 0) {
          this.foundInvoiceAddresses = [];
        }
        //const [oldPostcode, oldHouseNumber] = oldVal.split("|");
        const [newPostcode, newHouseNumber] = newVal.split("|");
        if (newPostcode != "" && newHouseNumber != "") {
          this.typeTimer = setTimeout(
            function (scope) {
              scope.validateInvoicePostcodeHouseNumber();
            },
            2000,
            this
          );
        }
      }
    },
    selectedAddress(newVal) {
      Logger.debug("selectedAddress", "index", newVal)();
      this.form.address.streetDisplay = this.foundAddresses[newVal].street;
      if (this.foundAddresses[newVal].letter != null) {
        this.form.address.addition = this.foundAddresses[newVal].letter;
      } else {
        this.form.address.addition = "";
      }
      this.form.address.city = this.foundAddresses[newVal].city;
      this.validatePostcode();
    },
    selectedInvoiceAddress(newVal) {
      Logger.debug("selectedAddress", "index", newVal)();
      this.form.invoice.address.streetDisplay =
        this.foundInvoiceAddresses[newVal].street;
      if (this.foundInvoiceAddresses[newVal].letter != null) {
        this.form.invoice.address.addition =
          this.foundInvoiceAddresses[newVal].letter;
      } else {
        this.form.invoice.address.addition = "";
      }
      this.form.invoice.address.city = this.foundAddresses[newVal].city;
      this.validateInvoicePostcode();
    },
    Email() {
      if (this.form.emailTimer !== null) {
        clearTimeout(this.form.emailTimer);
      }
      this.form.emailTimer = setTimeout(
        function (scope) {
          scope.validateEmail();
        },
        1000,
        this
      );
    },
  },
  data() {
    return {
      couponCode: "",
      isCompany: false,
      isInvoiceCompany: false,
      isValidVatNumber: false,
      isValidInvoiceVatNumber: false,
      showFullAddress: false,
      showFullInvoiceAddress: false,
      showVatMessage: "",
      showVatCheck: false,
      addressTimer: null,
      invoiceAddressTimer: null,
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      showManual: true,
      typeTimer: null,
      foundAddresses: [],
      foundAddress: "",
      foundInvoiceAddresses: [],
      foundInvoiceAddress: "",
      emailExists: false,
      selectedAddress: null,
      selectedInvoiceAddress: null,
      form: {
        email: "",
        emailState: null,
        emailError: "",
        emailTimer: null,
        password: "",
        passwordState: null,
        passwordError: "",
        passwordTimer: null,
        password_confirm: "",
        password_confirmState: null,
        password_confirmError: "",
        password_confirmTimer: null,
        company: "",
        companyState: null,
        companyError: "",
        companyTimer: null,
        firstname: "",
        firstnameState: null,
        firstnameError: "",
        firstnameTimer: null,
        lastname: "",
        lastnameState: null,
        lastnameError: "",
        lastnameTimer: null,
        coc_number: "",
        coc_numberState: null,
        coc_numberError: "",
        coc_numberTimer: null,
        taxvat: "",
        taxvatState: null,
        taxvatError: "",
        taxvatTimer: null,
        address: {
          country_code: "",
          postcode: "",
          postcodeState: null,
          postcodeError: "",
          postcodeTimer: null,

          street: "",
          streetDisplay: "",
          streetDisplayState: null,
          streetDisplayError: "",
          streetDisplayTimer: null,

          house_number: "",
          house_numberState: null,
          house_numberError: "",
          house_numberTimer: null,

          addition: "",
          city: "",
          cityState: null,
          cityError: "",
          cityTimer: null,

          telephone: "",
          telephoneState: null,
          telephoneError: "",
          telephoneTimer: null,
          default_billing: true,
          default_shipping: true,
        },
        invoice: {
          isCompany: true,

          company: "",
          companyState: null,
          companyError: "",
          companyTimer: null,
          firstname: "",
          firstnameState: null,
          firstnameError: "",
          firstnameTimer: null,
          lastname: "",
          lastnameState: null,
          lastnameError: "",
          lastnameTimer: null,
          coc_number: "",
          coc_numberState: null,
          coc_numberError: "",
          coc_numberTimer: null,
          taxvat: "",
          taxvatState: null,
          taxvatError: "",
          taxvatTimer: null,
          address: {
            country_code: "",
            postcode: "",
            postcodeState: null,
            postcodeError: "",
            postcodeTimer: null,

            street: "",
            streetDisplay: "",
            streetDisplayState: null,
            streetDisplayError: "",
            streetDisplayTimer: null,

            house_number: "",
            house_numberState: null,
            house_numberError: "",
            house_numberTimer: null,

            addition: "",
            city: "",
            cityState: null,
            cityError: "",
            cityTimer: null,

            telephone: "",
            telephoneState: null,
            telephoneError: "",
            telephoneTimer: null,
            default_billing: true,
            default_shipping: true,
          },
        },
        is_subscribed: false,
        checkbox2: false,
        manual: false,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
</style>