<template>
  <BaseLayout>
    <Breadcrumbs />
    <section class="checkout-details pt-3">
      <b-container>
        <div class="row">
          <b-col lg="4">
            <NotLoggedInCheckoutDetails v-if="!isLoggedIn" />
            <LoggedInCheckoutDetails v-if="isLoggedIn" />
          </b-col>
          <b-col lg="4" class="pt-20 pt-lg-0">
            <div class="group">
              <div class="group-title d-flex align-items-center mb-5">
                <div class="delivery-icon mr-8">
                  <img src="@/esf_lommel_dt1_europe/assets/delivery-icon.png" alt="delivery" />
                </div>
                <span>{{ $t("shipping_method") }}</span>
              </div>
              <ul class="delivery-options-list list-unstyled pl-0 m-0">
                <li
                  v-for="(deliveryOption, index) of deliveryOptions"
                  :key="`ship-${index}`"
                >
                  <b-form-radio
                    class="pl-30"
                    v-model="selectedShippingMethod"
                    name="deliveryOption"
                    :value="`${deliveryOption.carrier_code}|${deliveryOption.method_code}`"
                  >
                    <div class="d-flex">
                      <span class="mx-6 delivery-price d-block"
                        >&euro;&nbsp;{{
                          deliveryOption.amount.value.toFixed(2)
                        }}&nbsp;</span
                      >
                      <span class="d-block mx-6"
                        >{{ deliveryOption.carrier_title }} ({{
                          deliveryOption.method_title
                        }})</span
                      >
                    </div>
                  </b-form-radio>
                </li>
              </ul>
            </div>
            <div class="group">
              <div class="group-title d-flex align-items-center mb-5">
                <div class="payment-icon mr-8">
                  <img src="@/esf_lommel_dt1_europe/assets/payment-icon.png" alt="payment" />
                </div>
                <span class="d-block">{{ $t("payment_method") }}</span>
              </div>
              <div v-if="selectedShippingMethod != null">
                <ul class="payment-options-list list-unstyled p-0 m-0">
                  <li
                    v-for="(paymentOption, index) in paymentOptions"
                    :key="`pay-${index}`"
                  >
                    <b-form-radio
                      class="pl-30"
                      name="paymentOption"
                      :value="paymentOption.code"
                      v-on:change="checkAllSubOptions(paymentOption.code)"
                      v-model="selectedPaymentMethod"
                    >
                      <div class="d-flex align-items-center">
                        <div class="payment-option-img">
                          <img
                            :src="`${app_url}/media/graphql/icons/50x32/${paymentOption.paymentOptionImage}.png`"
                            :alt="paymentOption.code"
                          />
                        </div>
                        <span>{{ paymentOption.label }}</span>
                      </div>
                    </b-form-radio>
                    <div
                      v-for="(
                        paymentSubOption, index
                      ) in paymentOption.additional_fields"
                      :key="`pso-${index}`"
                    >
                      <div
                        v-if="paymentSubOption.type == 'select'"
                        class="pt-4"
                      >
                        <span class="d-block mb-15">{{
                          paymentSubOption.label
                        }}</span>
                        <b-form-group class="select--custom">
                          <b-form-select
                            :options="paymentSubOption.options"
                            text-field="label"
                            :value="
                              paymentMethodOptions[paymentOption.code][
                                paymentSubOption.code
                              ]
                            "
                            v-on:change="
                              setPaymentMethodOptions(
                                paymentOption.code,
                                paymentSubOption.code,
                                $event
                              )
                            "
                          >
                          </b-form-select>
                        </b-form-group>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </b-col>
          <b-col lg="4" class="pt-30 pt-lg-0">
            <div class="bg-gray py-10 px-20">
              <span class="group-title d-block"
                ><i class="lnr lnr-checkmark-circle mr-10"></i
                >{{ $t("order_overview") }}</span
              >
              <div class="cart-details pb-15">
                <b-link
                  href="#"
                  v-b-toggle.cart-details-collapse
                  class="d-block mb-10 group-subtitle border-0 collapse-opener"
                  >{{ $t("items_in_cart", { numberOf: cartCount }) }}</b-link
                >
                <b-collapse id="cart-details-collapse" class="bg-white" visible>
                  <div
                    class="d-flex align-items-center py-20"
                    v-for="cartItem of cartItems"
                    :key="cartItem.id"
                  >
                    <div class="img">
                      <img
                        :src="cartItem.product.image.url"
                        :alt="cartItem.product.name"
                      />
                    </div>
                    <div class="details">
                      <span class="name d-block mb-20">{{
                        cartItem.product.name
                      }}</span>
                      <div class="d-flex align-items-center">
                        <div class="quantity-input d-flex mr-5">
                          <b-link
                            href="#"
                            class="step-down-btn"
                            @click="
                              quantityDown(cartItem.id, cartItem.quantity)
                            "
                          ></b-link>
                          <input
                            type="number"
                            min="1"
                            :value="cartItem.quantity"
                            name="quantity"
                            ref="inputQuantity"
                          />
                          <b-link
                            href="#"
                            class="step-up-btn"
                            @click="quantityUp(cartItem.id, cartItem.quantity)"
                          ></b-link>
                        </div>
                        <div>
                          &euro;&nbsp;{{
                            cartItem.prices.row_total_including_tax.value.toFixed(
                              2
                            )
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </b-collapse>
              </div>
              <div class="order-overview">
                <span class="group-subtitle pb-15 d-block">{{
                  $t("order_overview")
                }}</span>
                <ul class="cost-list list-unstyled pl-0 m-0">
                  <li class="d-flex justify-content-between">
                    <span class="d-block mr-20">{{ $t("subtotal") }}</span>
                    <span class="d-block"
                      >&euro; {{ cartSubTotalPrice.toFixed(2) }}</span
                    >
                  </li>
                  <li
                    v-for="(price, index) in cartTotalDiscounts"
                    :key="`price-${index}`"
                    class="d-flex justify-content-between"
                  >
                    <span class="d-block mr-20">{{ price.label }}</span>
                    <span class="d-block"
                      >- &euro; {{ price.amount.value }}</span
                    >
                  </li>
                  <li
                    v-if="shippingMethod != null"
                    class="d-flex justify-content-between"
                  >
                    <span class="d-block mr-20"
                      >{{ shippingMethod.carrier_title }} /
                      {{ shippingMethod.method_title }}</span
                    >
                    <span class="d-block"
                      >&euro;
                      {{
                        shippingMethod.amount_including_tax.value.toFixed(2)
                      }}</span
                    >
                  </li>

                  <li
                    v-for="(price, index) in cartTotalTaxPrices"
                    :key="`tax-${index}`"
                    class="d-flex justify-content-between"
                  >
                    <span class="d-block mr-20">{{ price.label }}</span>
                    <span class="d-block">&euro; {{ price.amount.value }}</span>
                  </li>
                </ul>
                <div class="total-grp d-flex justify-content-between">
                  <span class="total">{{ $t("total") }}</span>
                  <span class="text-red"
                    >&euro; {{ cartTotalPrice.toFixed(2) }}</span
                  >
                </div>
              </div>
              <div class="coupon pt-15 mb-15">
                <b-link
                  class="collapse-opener group-subtitle d-block mb-10 border-0"
                  v-b-toggle.coupon-collapse
                  >{{ $t("coupon_discount") }}</b-link
                >
                <b-collapse id="coupon-collapse" collapsed class="pb-15">
                  <div class="d-flex">
                    <b-form-input
                      v-model="couponCode"
                      type="text"
                      :placeholder="$t('fill_out_discount_code')"
                    ></b-form-input>
                    <b-button @click="addCoupon" class="py-3">{{
                      $t("add")
                    }}</b-button>
                  </div>
                </b-collapse>
              </div>
              <b-form-checkbox
                class="pl-30"
                id="newsletter"
                v-model="SubscribeNewsletter"
                name="news-letter-subscribe"
                value="yes"
                unchecked-value="no"
                >{{ $t("subscription_newsletter") }}</b-form-checkbox
              >
              <span class="terms">{{ $t("terms_warning") }}</span>
              <b-button
                @click="submitForm"
                v-bind:disabled="btnDisable"
                variant="info"
                class="text-light w-100 text-uppercase checkout-btn"
                >{{ $t("pay") }}</b-button
              >
            </div>
          </b-col>
        </div>
      </b-container>
    </section>
  </BaseLayout>
</template>

<script>
import BaseLayout from "@/esf_lommel_dt1_europe/core/components//BaseLayout";
import NotLoggedInCheckoutDetails from "@/esf_lommel_dt1_europe/core/components//NotLoggedInCheckoutDetails";
import LoggedInCheckoutDetails from "@/esf_lommel_dt1_europe/core/components//LoggedInCheckoutDetails";
import Breadcrumbs from "@/esf_lommel_dt1_europe/core/components//Breadcrumbs";
import cartItemImg1 from "@/esf_lommel_dt1_europe/assets/productPageGalleryImg04.jpg";
import config from "@config";
import { Logger } from "@storefront/core/lib/logger";
import Cart from "@storefront/core/modules/cart/mixins";

export default {
  name: "CheckoutPage",
  components: {
    BaseLayout,
    NotLoggedInCheckoutDetails,
    LoggedInCheckoutDetails,
    Breadcrumbs,
  },
  mixins: [Cart],
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },

    paymentOptions() {
      var methods = this.$store.getters["cart/getPaymentMethods"];
      methods.forEach((value, index) => {
        Logger.debug("checkout", "PaymentOptions", [value, index])();
        methods[index]["paymentOptionImage"] = value["code"].replace(
          "multisafepay_",
          ""
        );
      });

      return methods;
    },
    deliveryOptions() {
      return this.$store.getters["cart/getShippingMethods"];
    },
    paymentMethodOptions() {
      return this.$store.getters["cart/getPaymentMethodOptions"];
    },
    shippingMethod() {
      return this.$store.getters["cart/getShippingMethod"];
    },
    selectedShippingMethod: {
      get: function () {
        const shmed = this.$store.getters["cart/getShippingMethod"];
        Logger.debug("selectedShippingMethod-get", "checkOut", shmed)();
        if (shmed != null) {
          return shmed.carrier_code + "|" + shmed.method_code;
        } else {
          return null;
        }
      },
      set: function (value) {
        const delOption = value.split("|");
        Logger.debug("selectedShippingMethod-set", "checkOut", value)();
        this.$store.dispatch("cart/addShippingMethod", {
          carrier: delOption[0],
          method: delOption[1],
        });
      },
    },
    selectedPaymentMethod: {
      get: function () {
        return this.$store.getters["cart/getPaymentMethod"];
      },
      set: function (value) {
        this.$store.commit("cart/setPaymentMethod", value);
      },
    },
    btnDisable() {
      if (this.$store.getters["cart/getShippingAddress"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getBillingAddress"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getShippingMethod"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getPaymentMethod"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getEmail"] == null) {
        return true;
      }
      if (this.$store.getters["cart/getItemsTotalQuantity"] == 0) {
        return true;
      }

      return false;
    },
  },
  watch: {
    selectedDeliveryOption() {
      Logger.debug(
        "selectedDeliveryOption",
        "checkout",
        this.selectedDeliveryOption
      )();
      if (this.selectedDeliveryOption != null) {
        const delOption = this.selectedDeliveryOption.split("|");
        this.$store.dispatch("cart/addShippingMethod", {
          carrier: delOption[0],
          method: delOption[0],
        });
      }
    },
  },
  created() {},
  mounted() {
    const bcrumb = { current: this.$t("checkout"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },

  methods: {
    async submitForm() {
      const getPayment = this.$store.getters["cart/getPaymentMethod"];
      const getPaymentMethodOptions =
        this.$store.getters["cart/getPaymentMethodOptions"];

      if (getPayment === "multisafepay_ideal") {
        if (
          !getPaymentMethodOptions ||
          !getPaymentMethodOptions[getPayment] ||
          !getPaymentMethodOptions[getPayment].issuer_id
        ) {
          return this.$emit("invalidatePayment");
        }
      }
      const retval = await this.$store.dispatch("cart/addPaymentMethod");
      localStorage.removeItem("orderHash");
      if (retval.order.hash) {
        localStorage.setItem("orderHash", retval.order.hash);
      }
      if (retval != false) {
        const url = retval.redirect_url.replace(config.app_url, "");
        if (this.isLoggedIn == true) {
          this.$store.dispatch("user/loadOrders");
        }
        this.$store.dispatch("cart/unLoad");
        if (url.includes("https://")) {
          window.location = url;
        } else {
          this.$store.dispatch("cart/loadCart");
          this.$router.push(
            url + "?transactionid=" + retval.order.increment_id
          );
        }
      }
    },
    async addCoupon() {
      if (this.couponCode != "") {
        const retval = await this.$store.dispatch("cart/addCouponCode", {
          code: this.couponCode,
        });
        Logger.debug("addCoupon", "cart.vue", retval)();
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("coupon_code"),
            text: this.$t("coupon_code_ok", { code: this.couponCode }),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          this.couponCode = "";
        }
      }
    },
    setPaymentMethodOptions(key, sub, value) {
      this.$store.commit("cart/updateCartPaymentMethodOptions", {
        key: key,
        sub: sub,
        value: value,
      });
    },
    checkAllSubOptions() {},
    submitCoupon(e) {
      e.preventDefault();
      console.log("couponSubmitted");
    },
  },

  data() {
    return {
      couponCode: "",
      app_url: config.app_url,
      isCompany: true,
      isValidVatNumber: false,
      showVatMessage: "",
      showVatCheck: false,
      emailReg:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      showManual: true,
      typeTimer: null,
      foundAddresses: [],
      foundAddress: "",
      selectedAddress: null,
      paymentOptionImage: "",
      paymentOptionsIsSelected: false,
      paymentSubOptions: {},
      checkoutForm: {
        selectedDeliveryOption: "DHL",
        selectedPaymentOption: "bancontact",
      },
      cartDetails: {
        noOfItems: 1,
        cartItems: [
          {
            id: "cartItem1",
            name: "Rogelli fietsjack winter Trani 3.0 Zwart/Rood",
            imgSrc: cartItemImg1,
            detailsLink: "#",
          },
        ],
      },
      orderOverview: [
        { field: "Winkelmandje subtotaal", value: "€ 75.55" },
        { field: "BTW 21%", value: "€ 7.55" },
        { field: "Verzendkosten", value: "€ 5.00" },
      ],
      TotalPrice: "€ 75.55",
      SubscribeNewsletter: "no",
    };
  },
};
</script>

<style lang="scss">
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.coupon {
  border-bottom: 1px solid #dfdfdf;
}
.checkout-details {
  padding-bottom: 33px;

  @include media("screen", ">=desktop") {
    padding-bottom: 0;
  }

  @include media("screen", ">=widescreen") {
    min-height: 1890px;
  }

  .half-width-form {
    width: calc(50% - 2px);
  }

  .bg-gray {
    background: #f6f6f6;
    margin-top: -10px;
  }

  .select--custom {
    .bv-no-focus-ring {
      position: relative;

      &:after {
        content: "\e874";
        font-family: "Linearicons-Free";
        position: absolute;
        top: 8px;
        right: 17px;
        z-index: 8;
      }
    }

    .custom-select {
      padding-right: 40px;
    }
  }

  .custom-select {
    background: none;
  }

  .delivery-price {
    font-family: "Work Sans", sans-serif;
    width: 50px;
    flex-shrink: 0;
  }

  input,
  select {
    font-family: "Gibson Regular";
    border-color: #dcdcdc;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    height: 40px;
  }

  small {
    font-size: 15px;
    font-weight: 300;
  }

  label {
    margin-bottom: 10px;
  }

  .custom-control-label {
    &:before {
      width: 15px;
      height: 15px;
      border-color: #000;
      top: 5px;
      left: -30px;
    }

    &:after {
      width: 18px;
      height: 18px;
      border-radius: 100%;
      top: 3px;
      left: -32px;
      opacity: 0;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {
    &:before {
      border-color: #ff8000;
      background-color: #ff8000;
    }
    &:after {
      opacity: 1;
    }
  }

  .custom-radio {
    .custom-control-input:checked {
      ~ .custom-control-label {
        &:after {
          top: 5px;
          left: -30px;
        }
      }
    }
    .custom-control-label {
      &:before {
        width: 18px;
        height: 18px;
      }
    }
  }

  .quantity-input {
    width: 100px;
    height: 36px;
    flex-shrink: 0;
    border: 1px solid #dcdcdc;
    border-radius: 4px;

    input[type="number"] {
      border: none;
      width: 31px;
      height: 34px;
      padding: 0;
      margin: 0;
      font-size: 17px;
      font-family: "Work Sans";
      text-align: center;
      font-weight: 600;
      outline: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type="number"] {
      -moz-appearance: textfield;
    }

    .step-down-btn,
    .step-up-btn {
      display: block;
      position: relative;
      width: 37px;
      height: 100%;
      flex-shrink: 0;

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 13px;
        height: 1px;
        background: #000;
      }
    }

    .step-up-btn {
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1px;
        height: 13px;
        background: #000;
      }
    }
  }

  .group-title {
    font-size: 20px;
    font-weight: normal;
    padding-bottom: 11px;
    border-bottom: 1px solid #dfdfdf;
    margin-bottom: 17px;
  }

  .group-subtitle {
    font-size: 16px;
    font-weight: normal;
    border-bottom: 1px solid #dfdfdf;
  }

  .delivery-options-list {
    padding-bottom: 12px;
    li {
      padding-top: 13px;
      padding-bottom: 8px;
      + li {
        border-top: 1px solid #dfdfdf;
      }
    }
  }

  .delivery-icon,
  .payment-icon {
    max-width: 32px;

    img {
      width: 100%;
    }
  }

  .payment-options-list {
    li {
      padding-top: 17px;
      padding-bottom: 10px;

      + li {
        border-top: 1px solid #dfdfdf;
      }
    }
  }

  .payment-option-img {
    width: 50px;
    margin-right: 12px;

    img {
      width: 100%;
    }
  }

  .account-details-txt {
    line-height: 1.5;
  }

  #cart-details-collapse {
    line-height: 1.3;
    margin: 0 -18px;
    padding: 0 18px;

    .img {
      width: 84px;
      margin-right: 10px;
      flex-shrink: 0;

      img {
        width: 100%;
      }
    }
  }

  .cost-list {
    padding-top: 14px;
    padding-bottom: 14px;

    li {
      + li {
        margin-top: 14px;
      }
    }
  }

  .total-grp {
    font-size: 20px;
    font-weight: 600;
    border-top: 1px solid #dfdfdf;
    border-bottom: 1px solid #dfdfdf;
    padding: 12px 0;
  }

  .terms {
    display: block;
    max-width: 270px;
    line-height: 1.2;
    margin-bottom: 15px;
  }

  .collapse-opener {
    position: relative;
    &:before {
      content: "\e873";
      font-family: "Linearicons-Free";
      position: absolute;
      right: 0;
      top: 0;
    }

    &.collapsed {
      &:before {
        content: "\e874";
      }
    }
  }

  .text-red {
    color: #f30000;
  }

  .checkout-btn {
    font-size: 17px;
  }
}
</style>
